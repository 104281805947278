export const simpleProducts = [
    { 'Name': 'HD Video Player', 'ID': 0 },
    { 'Name': 'SuperHD Video Player', 'ID': 1 },
    { 'Name': 'SuperPlasma 50', 'ID': 2 },
    { 'Name': 'SuperLED 50', 'ID': 3 },
    { 'Name': 'SuperLED 42', 'ID': 4 },
    { 'Name': 'SuperLCD 55', 'ID': 5 },
    { 'Name': 'SuperLCD 42', 'ID': 6 },
    { 'Name': 'SuperPlasma 65', 'ID': 7 },
    { 'Name': 'SuperLCD 70', 'ID': 8 },
    { 'Name': 'Projector Plus', 'ID': 9 },
    { 'Name': 'Projector PlusHT', 'ID': 10 },
    { 'Name': 'ExcelRemote IR', 'ID': 11 },
    { 'Name': 'ExcelRemote Bluetooth', 'ID': 12 },
    { 'Name': 'ExcelRemote IP', 'ID': 13 }
];

export const products = [{
    'ID': 1,
    'Name': 'HD Video Player',
    'Price': 330,
    'Current_Inventory': 225,
    'Backorder': 0,
    'Manufacturing': 10,
    'Category': 'Video Players',
    'ImageSrc': 'images/products/1.png'
}, {
    'ID': 2,
    'Name': 'SuperHD Video Player',
    'Price': 400,
    'Current_Inventory': 150,
    'Backorder': 0,
    'Manufacturing': 25,
    'Category': 'Video Players',
    'ImageSrc': 'images/products/2.png'
}, {
    'ID': 3,
    'Name': 'SuperPlasma 50',
    'Price': 2400,
    'Current_Inventory': 0,
    'Backorder': 0,
    'Manufacturing': 0,
    'Category': 'Televisions',
    'ImageSrc': 'images/products/3.png'
}, {
    'ID': 4,
    'Name': 'SuperLED 50',
    'Price': 1600,
    'Current_Inventory': 77,
    'Backorder': 0,
    'Manufacturing': 55,
    'Category': 'Televisions',
    'ImageSrc': 'images/products/4.png'
}, {
    'ID': 5,
    'Name': 'SuperLED 42',
    'Price': 1450,
    'Current_Inventory': 445,
    'Backorder': 0,
    'Manufacturing': 0,
    'Category': 'Televisions',
    'ImageSrc': 'images/products/5.png'
}, {
    'ID': 6,
    'Name': 'SuperLCD 55',
    'Price': 1350,
    'Current_Inventory': 345,
    'Backorder': 0,
    'Manufacturing': 5,
    'Category': 'Televisions',
    'ImageSrc': 'images/products/6.png'
}, {
    'ID': 7,
    'Name': 'SuperLCD 42',
    'Price': 1200,
    'Current_Inventory': 210,
    'Backorder': 0,
    'Manufacturing': 20,
    'Category': 'Televisions',
    'ImageSrc': 'images/products/7.png'
}, {
    'ID': 8,
    'Name': 'SuperPlasma 65',
    'Price': 3500,
    'Current_Inventory': 0,
    'Backorder': 0,
    'Manufacturing': 0,
    'Category': 'Televisions',
    'ImageSrc': 'images/products/8.png'
}, {
    'ID': 9,
    'Name': 'SuperLCD 70',
    'Price': 4000,
    'Current_Inventory': 95,
    'Backorder': 0,
    'Manufacturing': 5,
    'Category': 'Televisions',
    'ImageSrc': 'images/products/9.png'
}, {
    'ID': 10,
    'Name': 'DesktopLED 21',
    'Price': 175,
    'Current_Inventory': null,
    'Backorder': 425,
    'Manufacturing': 75,
    'Category': 'Monitors',
    'ImageSrc': 'images/products/10.png'
}, {
    'ID': 12,
    'Name': 'DesktopLCD 21',
    'Price': 170,
    'Current_Inventory': 210,
    'Backorder': 0,
    'Manufacturing': 60,
    'Category': 'Monitors',
    'ImageSrc': 'images/products/12.png'
}, {
    'ID': 13,
    'Name': 'DesktopLCD 19',
    'Price': 160,
    'Current_Inventory': 150,
    'Backorder': 0,
    'Manufacturing': 210,
    'Category': 'Monitors',
    'ImageSrc': 'images/products/13.png'
}, {
    'ID': 14,
    'Name': 'Projector Plus',
    'Price': 550,
    'Current_Inventory': null,
    'Backorder': 55,
    'Manufacturing': 10,
    'Category': 'Projectors',
    'ImageSrc': 'images/products/14.png'
}, {
    'ID': 15,
    'Name': 'Projector PlusHD',
    'Price': 750,
    'Current_Inventory': 110,
    'Backorder': 0,
    'Manufacturing': 90,
    'Category': 'Projectors',
    'ImageSrc': 'images/products/15.png'
}, {
    'ID': 16,
    'Name': 'Projector PlusHT',
    'Price': 1050,
    'Current_Inventory': 0,
    'Backorder': 75,
    'Manufacturing': 57,
    'Category': 'Projectors',
    'ImageSrc': 'images/products/16.png'
}, {
    'ID': 17,
    'Name': 'ExcelRemote IR',
    'Price': 150,
    'Current_Inventory': 650,
    'Backorder': 0,
    'Manufacturing': 190,
    'Category': 'Automation',
    'ImageSrc': 'images/products/17.png'
}, {
    'ID': 18,
    'Name': 'ExcelRemote Bluetooth',
    'Price': 180,
    'Current_Inventory': 310,
    'Backorder': 0,
    'Manufacturing': 0,
    'Category': 'Automation',
    'ImageSrc': 'images/products/18.png'
}, {
    'ID': 19,
    'Name': 'ExcelRemote IP',
    'Price': 200,
    'Current_Inventory': 0,
    'Backorder': 325,
    'Manufacturing': 225,
    'Category': 'Automation',
    'ImageSrc': 'images/products/19.png'
}];