<template>
  <div class="dx-fieldset">
    <div class="dx-field">
      <div class="dx-field-label">DropDownBox with embedded TreeView</div>
      <!-- <div class="dx-field-value">
        <DxDropDownBox
          v-model:value="treeBoxValue"
          :show-clear-button="true"
          :data-source="treeDataSource"
          value-expr="Id"
          display-expr="Name"
          placeholder="Select a value..."
          @value-changed="syncTreeViewSelection($event)"
        >
          <template #content="{}">
            <DxTreeView
              :ref="treeViewName"
              :data-source="treeDataSource"
              :select-by-click="true"
              :select-nodes-recursive="false"
              data-structure="plain"
              key-expr="Id"
              parent-id-expr="ParentId"
              selection-mode="multiple"
              show-check-boxes-mode="normal"
              display-expr="Name"
              @content-ready="syncTreeViewSelection($event)"
              @item-selection-changed="treeViewItemSelectionChanged($event)"
            />
          </template>
        </DxDropDownBox>
      </div> -->
    </div>
    <div class="dx-field">
      <div class="dx-field-label">DropDownBox with embedded DataGrid</div>
      <!-- <div class="dx-field-value">
        <DxDropDownBox
          v-model:value="gridBoxValue"
          :defer-rendering="false"
          :show-clear-button="true"
          :data-source="gridDataSource"
          display-expr="CompanyName"
          value-expr="ID"
          placeholder="Select a value..."
        >
          <template #content="{}">
            <DxDataGrid
              :data-source="gridDataSource"
              :columns="gridColumns"
              :hover-state-enabled="true"
              v-model:selected-row-keys="gridBoxValue"
            >
              <DxSelection mode="multiple" />
              <DxPaging :enabled="true" :page-size="10" />
              <DxFilterRow :visible="true" />
              <DxScrolling mode="virtual" />
            </DxDataGrid>
          </template>
        </DxDropDownBox>
      </div>
      {{ treeBoxValue }} -->
    </div>
    <div>
      <DxSelectBox
        search-enabled="true"
        :data-source="products"
        search-mode="contains"
        search-expr="Name"
        display-expr="Name"
        value-expr="ID"
      />

      Hi...
      <DxSelectBox
        v-model:value="selectValue"
        :search-enabled="true"
        search-mode="contains"
        search-expr="Name"
        display-expr="Name"
        value-expr="Id"
        :show-data-before-search="false"
        :data-source="testDataSource"
      >
      </DxSelectBox>
    </div>
  </div>
</template>

<script>
import CloudFun, {
  defineComponent,
  ref,
  onMounted,
  reactive,
} from "@cloudfun/core";
import { DxSelectBox } from "devextreme-vue";
import DxTreeView from "devextreme-vue/tree-view";
import "whatwg-fetch";
import { DxStores } from "../../services/data-source-service";
import createDataSource from "@/utils/devextreme";
import { products, simpleProducts } from "./data.js";
// import {
//   DxDataGrid,
//   DxSelection,
//   DxPaging,
//   DxFilterRow,
//   DxScrolling,
// } from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
export default defineComponent({
  components: {
    DxSelectBox,
    // DxTreeView,
    // DxDataGrid,
    // DxSelection,
    // DxPaging,
    // DxFilterRow,
    // DxScrolling,
  },
  setup(props) {
    const treeDataSource = ref({});
    const treeBoxValue = ref([]);
    const gridDataSource = ref({});
    const treeViewName = ref({});
    const selectValue = ref(null);
    const gridColumns = ["CompanyName", "City", "Phone"];
    const countryDataSource = DxStores.CountryStore(); // .CountryDataConfiguration();
    onMounted(() => {
      // treeDataSource.value = makeAsyncDataSource("categories.json");
      // gridDataSource.value = makeAsyncDataSource("customers.json");
      // //   treeBoxValue.value = [1];
      // console.log("treeDataSource.value", treeDataSource.value);
      // console.log("categoryDataSource", categoryDataSource);
    });
    const makeAsyncDataSource = (jsonFile) => {
      return new CustomStore({
        loadMode: "raw",
        key: "ID",
        load: function () {
          //   debugger;

          const a = fetch(`data/${jsonFile}`).then((resp) =>
            console.log("resp", resp)
          );
          const data = fetch(`data/${jsonFile}`).then(
            (response) => response.json(),
            (error) => {
              //   debugger;
              console.error("error", error);
            }
          );
          console.log("data", data);
          return data;
        },
      });
    };

    const syncTreeViewSelection = (e) => {
      const treeView =
        (e.component.selectItem && e.component) ||
        (treeViewName.value && treeViewName.value.instance);
      console.log("treeView: ", treeView);
      if (treeView) {
        if (e.value === null || undefined) {
          treeView.unselectAll();
        } else {
          const values = e.value || treeBoxValue;
          //   debugger;
          values &&
            values.forEach(function (value) {
              treeView.selectItem(value);
            });
        }
      }
    };

    const treeViewItemSelectionChanged = (e) => {
      console.log(
        "e.component.getSelectedNodeKeys()",
        e.component.getSelectedNodeKeys()
      );
      treeBoxValue.value = e.component.getSelectedNodeKeys();
      //   Object.assign(treeBoxValue, e.component.getSelectedNodeKeys());
      console.log("treeBoxValue", treeBoxValue);
      //   treeBoxValue = e.component.getSelectedNodeKeys();
    };
    const model = CloudFun.current?.model;
    // const categoryDataSource = createDataSource(model, "Id", "category/query");
    const testDataSource = createDataSource(model, "Id", "country/query");
    return {
      makeAsyncDataSource,
      syncTreeViewSelection,
      treeViewItemSelectionChanged,
      // categoryDataSource,
      treeDataSource,
      treeBoxValue,
      gridDataSource,
      treeViewName,
      gridColumns,
      countryDataSource,
      testDataSource,
      selectValue,
      products,
      simpleProducts,
    };
  },
  methods: {},
});
</script>

<style scoped></style>
